// @import "~bootstrap/scss/bootstrap";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
// 1. 最初に関数を組み込む（カラー、SVG、計算などが操作できるように）
@import "~bootstrap/scss/functions";
// 2. Include any default variable overrides here
// 2. ここにデフォルトの変数の再定義の設定を組み込む
/*
| フォントウェイト
*/
$font-weight-normal: 500;
/*
| ラインハイト
*/
$line-height-base: 1.85;
/*
| カラー
*/
$navy: #015383;
$primary: $navy;
/*
| 
*/
// 3. Include remainder of required Bootstrap stylesheets
// 3. 必須のBootstrapスタイルシートを組み込む
@import "~bootstrap/scss/variables";
// 4. Include any default map overrides here
// 4. ここにデフォルトのマップの再定義の設定を組み込む
/*
| テーマカラーにカスタムカラーを追加
*/
// $theme-colors: map-merge($theme-colors, $custom-colors);

/*
| マージンやパディングなど
*/
// @for $i from 0 through 25 {
//   $spacers: map-merge($spacers, (#{$i * 4}:#{$i * 4}px));
// }

// 5. Include remainder of required parts
// 5. 残りの必須のBootstrapスタイルシートを組み込む
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
// 6. 必要に応じて、オプションで他のパーツを組み込む
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// 7. 最後にオプションで、ユーティリティAPIを組み込んで、`_utilities.scss`のSassマップに基づいてクラスを生成
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here
// 8. ここにカスタムの設定を追加
/*
| HTML
*/
body,
header,
main,
aside,
footer,
article,
section,
div:not(.row),
li,
th,
td,
dt,
dd {
  &>*:first-child {
    margin-top: 0;
  }

  &>*:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
td,
dd {
  text-align: justify;
}

body {
  position: relative;
  font-feature-settings: "palt"1;
  letter-spacing: .05em;
  font-family: 'Avenir', 'Helvetica Neue', 'Helvetica', 'Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif;
  z-index: 1;
  padding-top: 62px;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

a {
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    border: 0;
  }

  img {
    border-style: none;
    outline: none;
    transition: opacity .35s, transform .35s;
  }

  &:hover {
    img {
      border: none;
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }

  &:active {
    img {
      outline: none;
      border: none;
    }
  }
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  width: auto;
}

ul,
ol {
  padding-left: 24px;

  li {
    line-height: 1.4;
  }

  &.fa-ul {
    margin-left: 24px;
  }
}

caption {
  color: inherit;
  caption-side: top;
}

/*
| フォントファミリー
*/
.ff {
  &-gothic {
    font-family: 'Avenir', 'Helvetica Neue', 'Helvetica', 'Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif;
  }

  &-mincho {
    font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝', 'Yu Mincho', '游明朝体', 'YuMincho', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
  }
}

/*
| フォントサイズ
*/
// @for $i from 8 through 64 {
//   .fz-#{$i + 2} {
//     font-size: #{$i + 2}px !important;
//   }

//   @media (min-width: 576px) {
//     .fz-sm-#{$i + 2} {
//       font-size: #{$i + 2}px !important;
//     }
//   }

//   @media (min-width: 768px) {
//     .fz-md-#{$i + 2} {
//       font-size: #{$i + 2}px !important;
//     }
//   }

//   @media (min-width: 992px) {
//     .fz-lg-#{$i + 2} {
//       font-size: #{$i + 2}px !important;
//     }
//   }

//   @media (min-width: 1200px) {
//     .fz-xl-#{$i + 2} {
//       font-size: #{$i + 2}px !important;
//     }
//   }

//   @media (min-width: 1400px) {
//     .fz-xxl-#{$i + 2} {
//       font-size: #{$i + 2}px !important;
//     }
//   }
// }

.fz {
  &-md {
    font-size: clamp(20px, 2.604vw, 24px);
  }

  &-lg {
    font-size: clamp(24px, 3.125vw, 32px);
  }
}

/*
| 見出し
*/

/*
| ヘッダー
*/
header {
  width: 100vw;
  overflow: hidden;

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($white, .25);
    z-index: 9999;
    padding-top: 16px;
    padding-bottom: 16px;

    @include media-breakpoint-up(md) {
      position: relative;
    }
  }

  .navbar-brand {
    z-index: 9999;
    display: block;
    text-indent: -9999em;
    width: clamp(200px, 34.375vw, 328px);
    height: clamp(28px, 4.687vw, 45px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/logo.png);
  }

  // ハンバーガーメニュー
  .nav-toggle {
    display: block;
    position: relative;
    width: 1.75rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 9999;
    margin-left: 16px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: $primary;
      position: absolute;
      transition: transform .5s, opacity .5s;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    &.show span {
      background-color: $white;

      &:nth-child(1) {
        transform: translateY(10px) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-12px) rotate(45deg);
      }
    }

  }

  // グローバルナビ
  #gnav {
    @include media-breakpoint-up(md) {
      width: 100%;
    }

    &.show {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 9998;

      #gnav-lists {
        display: block;

        .nav-menu-ul {
          opacity: 1;
        }
      }

    }

    #gnav-lists {
      display: none;
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9998;
      overflow-y: scroll;

      @include media-breakpoint-up(md) {
        position: relative;
        height: auto;
        overflow-y: unset;
        display: block;
      }
    }

    .nav-menu-ul {
      list-style: none;
      margin-top: 120px;
      padding: 0;
      z-index: 100;
      width: calc(100% - 64px);
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-right: 1px solid $secondary;
      }

      .menu-item {
        border-top: 1px dashed $white;
        animation-name: gnavAnime;
        animation-duration: .6s;
        animation-delay: .2s;
        animation-fill-mode: forwards;
        opacity: 0;

        &:last-child {
          border-bottom: 1px dashed $white;
        }

        .nav-link {
          color: $white;
          padding: 16px;
        }

        &.menu-item-has-children {
          position: relative;

          &::before {
            position: absolute;
            content: '';
            right: 16px;
            top: 23px;
            width: 8px;
            height: 8px;
            border-top: 2px solid $gray-200;
            border-right: 2px solid $gray-200;
            transform: rotate(135deg);
          }

          &.active {
            &::before {
              top: 25px;
              transform: rotate(-45deg);
            }

            .sub-menu {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .nav-item {
        @include media-breakpoint-up(md) {
          flex-basis: calc(100% / 5);
        }

        &+.nav-item {
          @include media-breakpoint-up(md) {
            border-left: 1px solid $secondary;
          }
        }
      }
    }

    .bg-nav {
      position: fixed;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $primary;
      transform: scale(0);
      right: -18px;
      top: -18px;
      transition: all .6s;
      z-index: 10;

      &.show {
        transform: scale(50);
      }
    }
  }
}

@keyframes gnavAnime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// ナビゲーションリンク
.nav-link {
  color: $white;

  .text {
    &-ja {
      @include media-breakpoint-up(md) {
        color: $black;
      }
    }

    &-en {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 12px;

      @include media-breakpoint-up(md) {
        color: $primary;
      }
    }
  }
}

/*
| ページタイトル
*/
.area-page-title {
  padding-top: clamp(24px, 9.375vw, 72px);
  padding-bottom: clamp(24px, 9.375vw, 72px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    color: $white;
  }
}

/*
| フッター
*/
footer {
  z-index: 1;

  .footer-nav {
    li {
      @include media-breakpoint-up(md) {
        min-width: calc(100% / 4);
      }
    }
  }
}

/*
| アウター
*/
.outer {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((70 / 99) * 100%);
  }

  >.inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/*
| object-fit
| <div class="object-fit-wrap">
| <img src=""alt=""class="object-fit object-fit-cover">
| </div>
*/
.object-fit {
  &-wrap {
    position: relative;

    .object-fit {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &-cover {
    object-fit: cover;
  }
}

/*
| ページトップに戻る
*/
#toPageTop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9998;

  a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: darken($primary, 7.5%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: darken($primary, 5%);
    }

    i {
      margin-top: -2px;
    }
  }
}

/*
| ボタン
*/
.btn {
  &-outline {
    &-white {
      border-width: 1px;
      border-color: $white;
      color: $white;

      &:hover,
      &:focus {
        border: 1px solid $white;
        background-color: $white;
        color: $primary;
      }
    }
  }
}

/*
| リスト
*/
.list-news {
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    padding: 8px;
    color: $primary;

    @include media-breakpoint-up(md) {
      padding: 16px;
    }

    +li {
      border-top: 1px dashed $primary;
    }
  }
}

.list-numbers {
  counter-reset: circleNumber;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    line-height: 1.5;
    padding-left: 30px;
    position: relative;

    &::before {
      content: counter(circleNumber);
      counter-increment: circleNumber;
      border: 1px solid;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      width: 22px;
      color: #ffffff;
      font-size: 85%;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

}

/*
| リンク
*/
.link-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -9999em;
}

/*
| Swiper
*/
.swiper {
  width: 100%;
  height: 100%;

  &-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slide-copy {
    top: 0;

    @include media-breakpoint-up(xl) {
      top: 44px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-color: #015383;
}

.swiper-button-prev {
  @include media-breakpoint-up(xl) {
    left: -44px;
  }
}

.swiper-button-next {
  @include media-breakpoint-up(xl) {
    right: -44px;
  }
}